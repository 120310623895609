import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
import { Pipe } from "../components/Pipe";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Checkbox" description="Accessible components to build custom, tri-state checkboxes in React" mdxType="SEO" />
    <h1 {...{
      "id": "checkbox"
    }}>{`Checkbox`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#mixedcheckbox-1" mdxType="TOCLink">MixedCheckbox</TOCLink>
    <TOCLink href="#usemixedcheckbox" mdxType="TOCLink">useMixedCheckbox</TOCLink>
    <TOCLink href="#customcheckbox-1" mdxType="TOCLink">CustomCheckbox</TOCLink>
    <TOCLink href="#customcheckboxcontainer" mdxType="TOCLink">CustomCheckboxContainer</TOCLink>
    <TOCLink href="#customcheckboxinput" mdxType="TOCLink">CustomCheckboxInput</TOCLink>
  </TOCList>
    </TOC>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/checkbox"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/checkbox`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#checkbox"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#checkbox`}</a></li>
    </ul>
    <p><inlineCode parentName="p">{`@reach/checkbox`}</inlineCode>{` provides two top-level components:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`MixedCheckbox`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`CustomCheckbox`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "mixedcheckbox"
    }}>{`MixedCheckbox`}</h2>
    <p>{`A MixedCheckbox simply renders an HTML input element where the `}<inlineCode parentName="p">{`type`}</inlineCode>{` attribute is equal to `}<inlineCode parentName="p">{`"checked"`}</inlineCode>{`. Whereas the native input element technically only has two states (`}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`false`}</inlineCode>{`), there is a third visual state of `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{` that is designed to suggest that a user has fulfilled some part of whatever the checkbox is meant to control. For example, you may have multiple hierarchal checkboxes nested:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`[-] All fruits
-- [ ] Apple
-- [x] Banana
-- [x] Orange
`}</code></pre>
    <p>{`In this example, the `}<inlineCode parentName="p">{`All fruits`}</inlineCode>{` checkbox is in an `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{` state because some (but not all) fruits in the list are checked. While this effect is possible with plain input components, the `}<inlineCode parentName="p">{`MixedCheckbox`}</inlineCode>{` component makes managing/syncing its state with the correct DOM attributes much simpler. All you have to do is pass the `}<inlineCode parentName="p">{`checked`}</inlineCode>{` state, and `}<inlineCode parentName="p">{`@reach/checkbox`}</inlineCode>{` handles the necessary aria attributes and related node data!`}</p>
    <p>{`A mixed checkbox is not something you can naturally toggle by simply clicking the box itself. As such, you should manage its state in your app by passing a `}<inlineCode parentName="p">{`checked`}</inlineCode>{` prop and an `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` handler. A mixed checkbox is necessarily controlled. If you use a `}<inlineCode parentName="p">{`MixedCheckbox`}</inlineCode>{` component without controlling its state, it will behave exactly the same way a native HTML input element behaves.`}</p>
    <p>{`If you don't need `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{` state, you should probably just use a native HTML input for your checkboxes. But of course, sometimes designers have some other ideas that call for a custom solution. In that case, the `}<inlineCode parentName="p">{`CustomCheckbox`}</inlineCode>{` component provides a customizable wrapper element that can be styled to fit your needs.`}</p>
    <h2 {...{
      "id": "customcheckbox"
    }}>{`CustomCheckbox`}</h2>
    <p>{`A `}<inlineCode parentName="p">{`CustomCheckbox`}</inlineCode>{` is useful because full control of a native HTML input's design is not always possible. You may want to provide custom check graphics or change the shape of the check or its color. This component provides a handy wrapper around a visually hidden native checkbox so that we avoid re-creating all of its native event behavior.`}</p>
    <p><inlineCode parentName="p">{`CustomCheckbox`}</inlineCode>{` uses our `}<inlineCode parentName="p">{`MixedCheckbox`}</inlineCode>{` so you get the same benefits for dealing with `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{` state when you use either!`}</p>
    <blockquote>
      <p parentName="blockquote">{`Accessibility Note: If you use our `}<inlineCode parentName="p">{`CustomCheckbox`}</inlineCode>{` component, you will still need to ensure that your styles follow the guidelines outlined in the `}<a parentName="p" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#checkbox"
        }}>{`WAI-ARIA specifications for checkboxes`}</a>{`. Pay special attention to focus styles for keyboard navigation. Our default styles provide focus styles by default.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/checkbox`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/checkbox`}</inlineCode>{`. Then import the components and styles that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/checkbox
# or
yarn add @reach/checkbox
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {
  CustomCheckbox,
  CustomCheckboxContainer,
  CustomCheckboxInput,
} from "@reach/checkbox";
import "@reach/checkbox/styles.css";
`}</code></pre>
    <p>{`If you are only using `}<inlineCode parentName="p">{`MixedCheckbox`}</inlineCode>{` or `}<inlineCode parentName="p">{`useMixedCheckbox`}</inlineCode>{`, there is no need to include the stylesheet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { MixedCheckbox, useMixedCheckbox } from "@reach/checkbox";
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <h3 {...{
      "id": "example-mixedcheckbox"
    }}>{`Example MixedCheckbox`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  const [checked, setChecked] = React.useState(true);
  return (
    <div>
      <label>
        <MixedCheckbox
          value="whatever"
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
          }}
        />
        I am feeling good today
      </label>
      <label>
        <MixedCheckbox checked="mixed" />
        Perma-mixed
      </label>
      <div style={{ marginTop: 10 }}>
        <button onClick={() => setChecked("mixed")}>
          I'm not sure how I feel!
        </button>
      </div>
    </div>
  );
}
`}</code></pre>
    <h3 {...{
      "id": "example-customcheckbox"
    }}>{`Example CustomCheckbox`}</h3>
    <p>{`With custom checkbox, you can choose between a high-level API where DOM elements
are not individually exposed as components, or use the composed API to access
each sub-component directly.`}</p>
    <h4 {...{
      "id": "high-level-customcheckbox-api"
    }}>{`High-level CustomCheckbox API`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function MyCheckbox({ children, ...props }) {
    return (
      <span className={\`example-custom-checkbox \${props.value}\`}>
        <label>
          <CustomCheckbox {...props} />
          {children}
        </label>
      </span>
    );
  }

  function Checklist() {
    return (
      <fieldset>
        <legend>What is your favorite fruit?</legend>
        <MyCheckbox name="favorite-fruit" value="apple" color="#B22222">
          Apple <span aria-hidden>🍎</span>
        </MyCheckbox>
        <MyCheckbox name="favorite-fruit" value="orange" color="#FF8C00">
          Orange <span aria-hidden>🍊</span>
        </MyCheckbox>
        <MyCheckbox name="favorite-fruit" value="banana" color="#FFD700">
          Banana <span aria-hidden>🍌</span>
        </MyCheckbox>
      </fieldset>
    );
  }

  return <Checklist />;
})();
`}</code></pre>
    <h4 {...{
      "id": "composed-customcheckbox-api"
    }}>{`Composed CustomCheckbox API`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function Example() {
    return (
      <div>
        <label style={{ display: "flex", alignItems: "center" }}>
          <MyCheckbox value="whatever" />
          This is a pretty cool checkbox; do you agree?
        </label>
        <br />
        <label style={{ display: "flex", alignItems: "center" }}>
          <MyCheckbox checked="mixed" value="something-else" />
          I'm just an example of what I'd look like if I had a mixed state.
        </label>
      </div>
    );
  }

  function MyCheckbox(props) {
    const [checkedState, setChecked] = React.useState(props.checked || false);
    const checked = props.checked != null ? props.checked : checkedState;

    return (
      <CustomCheckboxContainer
        checked={props.checked != null ? props.checked : checked}
        onChange={(event) => setChecked(event.target.checked)}
        style={getContainerStyle()}
      >
        <CustomCheckboxInput {...props} />
        <span aria-hidden style={getCheckStyle(checked)} />
      </CustomCheckboxContainer>
    );
  }

  function getContainerStyle() {
    return {
      background: "rgba(240, 240, 250, 0.8)",
      border: "2px solid rgba(0, 0, 0, 0.8)",
      borderRadius: "3px",
      height: 26,
      width: 26,
    };
  }

  function getCheckStyle(checked) {
    return {
      display: "block",
      position: "absolute",
      width: "60%",
      height: "60%",
      top: "50%",
      left: "50%",
      transform: \`translate(-50%, -50%) scaleX(\${!!checked ? 1 : 0}) scaleY(\${
        checked === true ? 1 : checked === "mixed" ? 0.4 : 0
      })\`,
      transition: "transform 200ms ease-out, background 200ms ease-out",
      zIndex: 1,
      background:
        checked === true
          ? "green"
          : checked === "mixed"
          ? "goldenrod"
          : "transparent",
    };
  }

  return <Example />;
})();
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "mixedcheckbox-1"
    }}>{`MixedCheckbox`}</h3>
    <p>{`Tri-state checkbox that accepts `}<inlineCode parentName="p">{`checked`}</inlineCode>{` values of `}<inlineCode parentName="p">{`true`}</inlineCode>{`, `}<inlineCode parentName="p">{`false`}</inlineCode>{` or `}<inlineCode parentName="p">{`"mixed"`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  let [favoriteCondiments, setFavoriteCondiments] = React.useState({
    mayo: true,
    mustard: true,
    ketchup: false,
  });

  // We can determine if all or some of the nested checkboxes are selected and
  // use that to determine the state of our parent checkbox.
  let allCondimentsChecked = Object.keys(favoriteCondiments).every(
    (condiment) => favoriteCondiments[condiment] === true
  );
  let someCondimentsChecked = allCondimentsChecked
    ? false
    : Object.keys(favoriteCondiments).some(
        (condiment) => favoriteCondiments[condiment] === true
      );

  let parentIsChecked = allCondimentsChecked
    ? true
    : someCondimentsChecked
    ? "mixed"
    : false;

  // When we toggle a parent checkbox, we expect all of the nested checkboxes
  // to toggle with it.
  function handleParentChange(event) {
    setFavoriteCondiments(
      Object.keys(favoriteCondiments).reduce(
        (state, condiment) => ({
          ...state,
          [condiment]: !allCondimentsChecked,
        }),
        {}
      )
    );
  }

  function handleChildChange(event) {
    let { checked, value } = event.target;
    setFavoriteCondiments({
      ...favoriteCondiments,
      [value]: checked,
    });
  }

  return (
    <fieldset>
      <label>
        <MixedCheckbox
          value="condiments"
          checked={parentIsChecked}
          onChange={handleParentChange}
        />
        {allCondimentsChecked ? "Unselect" : "Select"} all condiments
      </label>
      <fieldset style={{ margin: "1rem 0 0", padding: "1rem 1.5rem" }}>
        <legend>Condiments</legend>

        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          {Object.entries(favoriteCondiments).map(([value, state]) => (
            <li key={value}>
              <label>
                <MixedCheckbox
                  name="condiment"
                  value={value}
                  checked={state}
                  onChange={handleChildChange}
                />
                {value}
              </label>
            </li>
          ))}
        </ul>
      </fieldset>
    </fieldset>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "mixedcheckbox-props"
    }}>{`MixedCheckbox Props`}</h4>
    <p><inlineCode parentName="p">{`MixedCheckbox`}</inlineCode>{` inherits its props from the `}<inlineCode parentName="p">{`React.ComponentProps<'input'>`}</inlineCode>{` type, with additional context documented below.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/forms.html"
      }}>{`Check out the React documentation for additional information about form inputs in React.`}</a></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#mixedcheckbox-checked"
            }}><inlineCode parentName="a">{`checked`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`"mixed"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#mixedcheckbox-onchange"
            }}><inlineCode parentName="a">{`onChange`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "mixedcheckbox-checked"
    }}>{`MixedCheckbox checked`}</h5>
    <p><inlineCode parentName="p">{`checked?: boolean | "mixed`}</inlineCode></p>
    <p>{`Whether or not the checkbox is checked or in a `}<inlineCode parentName="p">{`mixed`}</inlineCode>{` (indeterminate) state.`}</p>
    <h5 {...{
      "id": "mixedcheckbox-onchange"
    }}>{`MixedCheckbox onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;`}</inlineCode></p>
    <p>{`The callback that is fired when the checkbox value is changed.`}</p>
    <h3 {...{
      "id": "usemixedcheckbox"
    }}>{`useMixedCheckbox`}</h3>
    <p>{`A hook that can be used to turn any HTML input component into a tri-state checkbox.`}</p>
    <p>{`You must create a ref and pass it along with additional arguments to return a props object and state-related data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example({ disabled = false }) {
  const [checked, setChecked] = React.useState(true);
  let inputRef = React.useRef(null);
  let [inputProps, stateData] = useMixedCheckbox(inputRef, {
    // boolean
    // A mixed checkbox may receive either \`defaultChecked\` or \`checked\`
    // values, but not both!
    defaultChecked: undefined,
    // boolean | "mixed"
    checked,
    // (event: React.ChangeEvent<HTMLInputElement>) => void
    onChange: (event) => setChecked(event.target.checked),
    // boolean
    disabled,
  });
  return (
    <div>
      <label>
        <input {...inputProps} ref={inputRef} />
        How about this cool example?
      </label>
      <button onClick={() => setChecked("mixed")}>Mix it up</button>
      <hr />
      Current state is: <pre>{String(stateData.checked)}</pre>
    </div>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "usemixedcheckbox-signature"
    }}>{`useMixedCheckbox signature`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function useMixedCheckbox(
  ref: React.RefObject<HTMLInputElement | null>,
  args?: {
    checked?: boolean | "mixed";
    defaultChecked?: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  },
  functionOrComponentName: string = "useMixedCheckbox"
): [
  {
    "aria-checked": boolean | "mixed";
    checked: boolean;
    disabled: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
    type: "checkbox";
  },
  { checked: boolean | "mixed" }
];
`}</code></pre>
    <h3 {...{
      "id": "customcheckbox-1"
    }}>{`CustomCheckbox`}</h3>
    <p>{`A checkbox component with a wrapper element for custom styling.`}</p>
    <h4 {...{
      "id": "customcheckbox-css-selectors"
    }}>{`CustomCheckbox CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-custom-checkbox] {
}
[data-reach-custom-checkbox][data-state="checked"] {
}
[data-reach-custom-checkbox][data-state="unchecked"] {
}
[data-reach-custom-checkbox][data-state="mixed"] {
}
`}</code></pre>
    <h4 {...{
      "id": "customcheckbox-props"
    }}>{`CustomCheckbox Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckbox-span-props"
            }}><inlineCode parentName="a">{`span`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckbox-checked"
            }}><inlineCode parentName="a">{`checked`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`"mixed"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckbox-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckbox-defaultchecked"
            }}><inlineCode parentName="a">{`defaultChecked`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckbox-disabled"
            }}><inlineCode parentName="a">{`disabled`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckbox-name"
            }}><inlineCode parentName="a">{`name`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckbox-onchange"
            }}><inlineCode parentName="a">{`onChange`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckbox-value"
            }}><inlineCode parentName="a">{`value`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number\``}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "customcheckbox-span-props"
    }}>{`CustomCheckbox span props`}</h5>
    <p>{`All props are spread to an underlying `}<inlineCode parentName="p">{`span`}</inlineCode>{` element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<CustomCheckbox className="cool-checkbox" />
`}</code></pre>
    <h5 {...{
      "id": "customcheckbox-checked"
    }}>{`CustomCheckbox checked`}</h5>
    <p><inlineCode parentName="p">{`checked?: boolean | "mixed"`}</inlineCode></p>
    <p>{`Whether or not the checkbox is checked or in a `}<inlineCode parentName="p">{`mixed`}</inlineCode>{` (indeterminate) state.`}</p>
    <h5 {...{
      "id": "customcheckbox-children"
    }}>{`CustomCheckbox children`}</h5>
    <p><inlineCode parentName="p">{`children?: React.ReactNode;`}</inlineCode></p>
    <p>{`A `}<inlineCode parentName="p">{`CustomCheckbox`}</inlineCode>{` can accept any React node as children so long as the rendered content is valid HTML. It is best to avoid adding interactive elements inside of a `}<inlineCode parentName="p">{`CustomCheckbox`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Example({ innerStyle, ...props }) {
  return (
    <span>
      <label>
        <CustomCheckbox {...props}>
          <span aria-hidden style={...innerStyle} />
        </CustomCheckbox>
      </label>
    </span>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "customcheckbox-defaultchecked"
    }}>{`CustomCheckbox defaultChecked`}</h5>
    <p><inlineCode parentName="p">{`defaultChecked?: boolean`}</inlineCode></p>
    <p>{`For uncontrolled checkbox components, `}<inlineCode parentName="p">{`defaultChecked`}</inlineCode>{` dictates whether or not the default initial state for a checkbox is `}<inlineCode parentName="p">{`checked`}</inlineCode>{`.`}</p>
    <p>{`Because any checkbox with a `}<inlineCode parentName="p">{`mixed`}</inlineCode>{` state must be controlled by the app, `}<inlineCode parentName="p">{`defaultChecked`}</inlineCode>{` only accepts `}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`false`}</inlineCode>{` values.`}</p>
    <h5 {...{
      "id": "customcheckbox-disabled"
    }}>{`CustomCheckbox disabled`}</h5>
    <p><inlineCode parentName="p">{`disabled?: boolean`}</inlineCode></p>
    <p>{`Whether or not the checkbox form input is disabled.`}</p>
    <h5 {...{
      "id": "customcheckbox-name"
    }}>{`CustomCheckbox name`}</h5>
    <p><inlineCode parentName="p">{`name?: string`}</inlineCode></p>
    <p>{`The `}<inlineCode parentName="p">{`name`}</inlineCode>{` attribute passed to the checkbox form input.`}</p>
    <h5 {...{
      "id": "customcheckbox-onchange"
    }}>{`CustomCheckbox onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;`}</inlineCode></p>
    <p>{`The callback that is fired when the checkbox value is changed.`}</p>
    <h5 {...{
      "id": "customcheckbox-value"
    }}>{`CustomCheckbox value`}</h5>
    <p><inlineCode parentName="p">{`value?: string | number`}</inlineCode></p>
    <p>{`The `}<inlineCode parentName="p">{`value`}</inlineCode>{` attribute passed to the checkbox form input.`}</p>
    <h3 {...{
      "id": "customcheckboxcontainer"
    }}>{`CustomCheckboxContainer`}</h3>
    <p>{`Wrapper component and context provider for a custom checkbox. It should be used in conjunction with the `}<inlineCode parentName="p">{`CustomCheckboxInput`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "customcheckboxcontainer-css-selectors"
    }}>{`CustomCheckboxContainer CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-custom-checkbox-container] {
}
[data-reach-custom-checkbox-container][data-state="checked"] {
}
[data-reach-custom-checkbox-container][data-state="unchecked"] {
}
[data-reach-custom-checkbox-container][data-state="mixed"] {
}
`}</code></pre>
    <h4 {...{
      "id": "customcheckboxcontainer-props"
    }}>{`CustomCheckboxContainer Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckboxcontainer-span-props"
            }}><inlineCode parentName="a">{`span`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckboxcontainer-checked"
            }}><inlineCode parentName="a">{`checked`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`"mixed"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckboxcontainer-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckboxcontainer-defaultchecked"
            }}><inlineCode parentName="a">{`defaultChecked`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckboxcontainer-disabled"
            }}><inlineCode parentName="a">{`disabled`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#customcheckboxcontainer-onchange"
            }}><inlineCode parentName="a">{`onChange`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "customcheckboxcontainer-span-props"
    }}>{`CustomCheckboxContainer span props`}</h5>
    <p>{`All props are spread to an underlying `}<inlineCode parentName="p">{`span`}</inlineCode>{` element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<CustomCheckboxContainer className="cool-checkbox">
  <CustomCheckboxInput />
</CustomCheckboxContainer>
`}</code></pre>
    <h5 {...{
      "id": "customcheckboxcontainer-checked"
    }}>{`CustomCheckboxContainer checked`}</h5>
    <p><inlineCode parentName="p">{`checked?: boolean | "mixed"`}</inlineCode></p>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#customcheckbox-checked"
      }}><inlineCode parentName="a">{`CustomCheckbox`}</inlineCode>{` `}<inlineCode parentName="a">{`checked`}</inlineCode></a>{`.`}</p>
    <p>{`This prop is assigned to the `}<inlineCode parentName="p">{`CustomCheckboxContainer`}</inlineCode>{` and passed to the `}<inlineCode parentName="p">{`CustomCheckboxInput`}</inlineCode>{` via the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html"
      }}>{`React Context API`}</a>{`.`}</p>
    <h5 {...{
      "id": "customcheckboxcontainer-children"
    }}>{`CustomCheckboxContainer children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode | ((args: { checked: boolean | "mixed", inputRef: React.RefObject, focused: boolean }) => JSX.Element)`}</inlineCode></p>
    <p>{`A `}<inlineCode parentName="p">{`CustomCheckboxContainer`}</inlineCode>{` can accept a React node or render prop function as its child. It should always have one `}<inlineCode parentName="p">{`CustomCheckboxInput`}</inlineCode>{` component as a descendant.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Example({ children, name, value, id, label, ...props }) {
  return (
    <span>
      <CustomCheckbox {...props}>
        {({ checked, focused }) => (
          <span
            aria-hidden
            style={{
              display: "block",
              outline: focused ? "2px solid aqua" : undefined,
            }}
          >
            {checked === "mixed" ? "⛔" : checked ? "✅" : "❌"}
            <CustomCheckboxInput id={id} name={name} value={value} />
          </span>
        )}
      </CustomCheckbox>
      <label htmlFor={id}>{label}</label>
    </span>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "customcheckboxcontainer-defaultchecked"
    }}>{`CustomCheckboxContainer defaultChecked`}</h5>
    <p><inlineCode parentName="p">{`defaultChecked?: boolean`}</inlineCode></p>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#customcheckbox-defaultchecked"
      }}><inlineCode parentName="a">{`CustomCheckbox`}</inlineCode>{` `}<inlineCode parentName="a">{`defaultChecked`}</inlineCode></a>{`.`}</p>
    <p>{`This prop is assigned to the `}<inlineCode parentName="p">{`CustomCheckboxContainer`}</inlineCode>{` and passed to the `}<inlineCode parentName="p">{`CustomCheckboxInput`}</inlineCode>{` via the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html"
      }}>{`React Context API`}</a>{`.`}</p>
    <h5 {...{
      "id": "customcheckboxcontainer-disabled"
    }}>{`CustomCheckboxContainer disabled`}</h5>
    <p><inlineCode parentName="p">{`disabled?: boolean`}</inlineCode></p>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#customcheckbox-disabled"
      }}><inlineCode parentName="a">{`CustomCheckbox`}</inlineCode>{` `}<inlineCode parentName="a">{`disabled`}</inlineCode></a>{`.`}</p>
    <p>{`This prop is assigned to the `}<inlineCode parentName="p">{`CustomCheckboxContainer`}</inlineCode>{` and passed to the `}<inlineCode parentName="p">{`CustomCheckboxInput`}</inlineCode>{` via the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html"
      }}>{`React Context API`}</a>{`.`}</p>
    <h5 {...{
      "id": "customcheckboxcontainer-onchange"
    }}>{`CustomCheckboxContainer onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;`}</inlineCode></p>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#customcheckbox-onchange"
      }}><inlineCode parentName="a">{`CustomCheckbox`}</inlineCode>{` `}<inlineCode parentName="a">{`onChange`}</inlineCode></a>{`.`}</p>
    <p>{`This prop is assigned to the `}<inlineCode parentName="p">{`CustomCheckboxContainer`}</inlineCode>{` and passed to the `}<inlineCode parentName="p">{`CustomCheckboxInput`}</inlineCode>{` via the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html"
      }}>{`React Context API`}</a>{`.`}</p>
    <h3 {...{
      "id": "customcheckboxinput"
    }}>{`CustomCheckboxInput`}</h3>
    <p>{`Component to render the HTML input element for our custom checkbox. The rendered element should be visually hidden and exists only to manage its state and hold a form name and value.`}</p>
    <h4 {...{
      "id": "customcheckboxinput-css-selectors"
    }}>{`CustomCheckboxInput CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-custom-checkbox-input] {
}
[data-reach-custom-checkbox-input][aria-checked="true"] {
}
[data-reach-custom-checkbox-input][aria-checked="false"] {
}
[data-reach-custom-checkbox-input][aria-checked="mixed"] {
}
`}</code></pre>
    <h4 {...{
      "id": "customcheckboxinput-props"
    }}>{`CustomCheckboxInput Props`}</h4>
    <p><inlineCode parentName="p">{`CustomCheckboxInput`}</inlineCode>{` inherits its props from the `}<inlineCode parentName="p">{`React.ComponentProps<'input'>`}</inlineCode>{` type, excluding:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#customcheckboxcontainer-checked"
        }}><inlineCode parentName="a">{`checked`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#customcheckboxcontainer-defaultchecked"
        }}><inlineCode parentName="a">{`defaultChecked`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#customcheckboxcontainer-disabled"
        }}><inlineCode parentName="a">{`disabled`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#customcheckboxcontainer-onchange"
        }}><inlineCode parentName="a">{`onChange`}</inlineCode></a>{`\``}</li>
    </ul>
    <p>{`Each of these props, if needed, should instead be assigned to `}<inlineCode parentName="p">{`CustomCheckboxContainer`}</inlineCode>{`. They are passed to the `}<inlineCode parentName="p">{`CustomCheckboxInput`}</inlineCode>{` via the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html"
      }}>{`React Context API`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/forms.html"
      }}>{`Check out the React documentation for additional information about form inputs in React.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      